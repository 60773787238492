import React, { memo } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  SanityB2BPageBuilderPage,
  SanityHeroModule
} from '../../../../graphql-types';
import { useNavigate } from '@reach/router';
import { default as PageBuilderBase } from './page-builder-base';
import { default as SEO } from '../../../components/SEO';
import B2BHeader from '../../organisms/navigation/b2b-nav/b2b-main-nav';
import { getB2bLinks } from '../../../lib/b2b-utils';

export interface B2BPageBuilderTemplateProps extends PageProps {
  data: {
    sanityB2BPageBuilderPage: SanityB2BPageBuilderPage;
  };
  pageContext: {
    slug: string;
  };
}

export default memo(
  function B2BPageBuilderTemplate(
    props: B2BPageBuilderTemplateProps
  ): React.ReactElement {
    const navigate = useNavigate();
    const data = props?.data;
    const path = props?.pageContext?.slug;

    // If for some reason the query fails and the props.data are empty redirect to the home page.
    if (!data?.sanityB2BPageBuilderPage?.components) {
      if (typeof window !== 'undefined') {
        navigate('/');
      }
      return null;
    }

    const {
      components,
      metaDescription,
      metaTitle,
      metaImage,
      parsleyHealthImage,
      partnerImage
    } = data?.sanityB2BPageBuilderPage;
    const { signUpUrl } = getB2bLinks(path);

    // Modify links in the hero component
    const heroComponentIndex = components?.items?.findIndex(
      component => component._type === 'heroModule'
    );
    const heroComponent: SanityHeroModule =
      components.items[heroComponentIndex];

    // TODO - add an option to override default CTA URL
    // matts - this is a fix to avoid overriding the Hilton CTA link. we want to
    // to be able to set it in sanity only.
    if (
      heroComponent?.textLockup?.primaryCta?.destination &&
      !path?.includes('hilton')
    ) {
      heroComponent.textLockup.primaryCta.destination = signUpUrl;
    }

    return (
      <>
        {metaTitle && (
          <SEO
            pageDescription={metaDescription}
            pageTitle={metaTitle}
            pageImage={metaImage}
          />
        )}
        <PageBuilderBase
          components={components}
          navType="B2B"
          customNav={
            <B2BHeader
              slug={path}
              companyLogo={parsleyHealthImage}
              partnerLogo={partnerImage}
            />
          }
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    const prev =
      prevProps?.data?.sanityB2BPageBuilderPage?.components?.items.length;
    const next =
      nextProps?.data?.sanityB2BPageBuilderPage?.components?.items.length;

    return prev === next;
  }
);

export const query = graphql`
  query PageBuilderB2BQuery($id: String!) {
    sanityB2BPageBuilderPage(id: { eq: $id }) {
      components {
        ...SanityPageBuilderComponentsListFragment
      }
      metaTitle
      metaDescription
      slug {
        current
      }
      parsleyHealthImage {
        ...SanityImageFragmentV2
      }
      partnerImage {
        ...SanityImageFragmentV2
      }
      metaImage {
        hotspot {
          height
          width
          x
          y
        }
        crop {
          bottom
          left
          right
          top
        }
      }
    }
  }
`;
